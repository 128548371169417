import React from 'react';
import { Router } from '@reach/router';
// import { useBeforeUnload } from 'react-use';
import { LoyaltyRegister, LoyaltyRedeem } from '../components/routes';

const LoyaltyPage = () => {
  // useBeforeUnload('Enable', 'You have unsaved changes, are you sure?');
  return (
    <Router basepath="loyalty">
      <LoyaltyRegister path=":id/register" />
      <LoyaltyRedeem path=":id/redeem" />
      <LoyaltyRedeem path=":id/redeem/:code" />
    </Router>
  );
};

export default LoyaltyPage;
